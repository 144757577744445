import React from 'react';
import { FONT_FACES, FONT_FAMILY } from 'src/consts/fonts';
const proximaNovaFontFace = FONT_FACES[FONT_FAMILY.PROXIMA_NOVA];

const WebSiteFonts = () => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: proximaNovaFontFace,
      }}
    ></style>
  );
};

export default WebSiteFonts;
