export default {
  en: [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'Chowbus, Inc.',
      alternateName: 'Chowbus POS',
      url: 'https://pos.chowbus.com/',
      logo: 'https://pos-static.chowbus.com/assets/logo@3x.png',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          email: 'pos@chowbus.com',
          contactType: 'customer service',
          areaServed: 'US',
        },
        {
          '@type': 'ContactPoint',
          email: 'pos@chowbus.com',
          contactType: 'sales',
          areaServed: 'US',
        },
      ],

      sameAs: [
        'https://www..facebook.com/chowbus',
        'https://www.instagram.com/chowbus_official/',
        'https://www.linkedin.com/company/chowbus/',
        'https://www.youtube.com/@chowbus3594',
        'https://en.wikipedia.org/wiki/Chowbus',
        'https://pos.chowbus.com/',
      ],
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Service',
      name: 'Chowbus Hot Pot POS System',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.9',
        reviewCount: '168',
      },
      offers: {
        '@type': 'AggregateOffer',
        lowPrice: '100',
        highPrice: '600',
        priceCurrency: 'USD',
      },
    },
  ],
  zh: [],
};
