import React from 'react';
import Head from 'next/head';

/**
 * Adds JSONLD structured data into the head of the html document
 * @param {object} props
 * @param {string} props.jsonLD
 */
const JSONLDHead = (props) => {
  const { jsonLD } = props;
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLD }}></script>
    </Head>
  );
};

export default JSONLDHead;
