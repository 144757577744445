export default {
  en: [
    {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Chowbus POS | Restaurant Point of Sale & Management System',
      url: 'https://pos.chowbus.com/',
      description:
        'Revolutionize your restaurant with Chowbus POS - the all-in-one restaurant POS system. Save $8,000/mo in labor costs and boost growth by 25%. See how it works!',
    },

    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'Chowbus, Inc.',
      alternateName: 'Chowbus POS',
      url: 'https://pos.chowbus.com/',
      logo: 'https://pos-static.chowbus.com/assets/logo@3x.png',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          email: 'pos@chowbus.com',
          contactType: 'customer service',
          areaServed: 'US',
        },
        {
          '@type': 'ContactPoint',
          email: 'pos@chowbus.com',
          contactType: 'sales',
          areaServed: 'US',
        },
      ],
      sameAs: [
        'https://www..facebook.com/chowbus',
        'https://www.instagram.com/chowbus_official/',
        'https://www.linkedin.com/company/chowbus/',
        'https://www.youtube.com/@chowbus3594',
        'https://en.wikipedia.org/wiki/Chowbus',
        'https://pos.chowbus.com/',
      ],
    },
    {
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      name: 'Chowbus Introduction',
      description: 'Introduction video for Chowbus.',
      thumbnailUrl:
        'https://images.ctfassets.net/n3m63hxm9c13/6jVOHLxITj21TqCThhGW4k/4e0fb8559e632234573bb07dbb1c2fc8/chowbus_introduction_thumbnail.webp',
      contentUrl: 'https://pos-static.chowbus.com/chowbus_introduction_en_720.mp4',
      uploadDate: '2024-06-21T00:00:00.000Z',
      duration: 'T1M24S',
      embedUrl: 'https://pos-static.chowbus.com/chowbus_introduction_en_720.mp4',
      width: 1280,
      height: 720,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: 'Chowbus POS System',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.9',
        reviewCount: '3582',
        description: 'Average rating of all products listed on this page',
      },
      offers: {
        '@type': 'AggregateOffer',
        lowPrice: '100',
        highPrice: '600',
        priceCurrency: 'USD',
      },
    },
  ],
  zh: [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Chowbus POS',
      url: 'https://pos.chowbus.com/zh',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://pos.chowbus.com/zh/search?q={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: 'Chowbus POS System',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.9',
        reviewCount: '3582',
        description: 'Average rating of all products listed on this page',
      },
      offers: {
        '@type': 'AggregateOffer',
        lowPrice: '100',
        highPrice: '600',
        priceCurrency: 'USD',
      },
    },
  ],
};
