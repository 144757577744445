import React, { useCallback, useEffect } from 'react';
import { Grid } from 'antd';
import { HomeGetADemoGuide } from 'src/components/GetADemoGuide';
import indexStyles from '../index.less';
import router from 'next/router';
import { useIntl } from 'react-intl';
import { JoinClassName } from 'src/utils/utils';
import CommonSection from 'src/components/POSWebsite/CommonSection';

const { useBreakpoint } = Grid;

const ScheduleYourFreeDemo = ({
  showModel = 'lightGray_background',
  showOnModal = false,
  triggerSource = 'bottom',
  onCloseWhenshowOnModal = () => {},
}) => {
  const intl = useIntl();
  const onAfterSubmit = useCallback(() => {
    router.push('/get-a-demo/confirm');
  }, []);

  return (
    <CommonSection
      title={''}
      className={JoinClassName(indexStyles.max1440Width, indexStyles.scheduleYourFreeDemo)}
      outerClassName={JoinClassName(
        showModel === 'lightGray_background' ? indexStyles.lightGrayBackgroundcolor : indexStyles.whiteBackgroundcolor,
        showOnModal ? indexStyles.demoFormOnModalBackgroundcolor : ''
      )}
    >
      <HomeGetADemoGuide
        triggerSource={triggerSource}
        showOnModal={showOnModal}
        onCloseWhenshowOnModal={onCloseWhenshowOnModal}
        showSkipNow={false}
        onAfterSubmit={onAfterSubmit}
        title={intl.formatMessage({ id: 'home.get-demo.form.title' })}
        subTitle={intl.formatMessage({ id: 'home.get-demo.form.subtitle' })}
      />
    </CommonSection>
  );
};

export default ScheduleYourFreeDemo;
