import { default as homepage } from './home';
import { default as pointOfSable } from './products/point_of_sale';
import { default as handheld } from './products/handheld';
import { default as tableordering } from './products/tablet';
import { default as qrcode } from './products/qrcode';
import { default as kiosk } from './products/kiosk';
import { default as loyalty } from './products/loyalty';
import { default as onlineordering } from './products/online-ordering';
import { default as thirdpartyIntegration } from './products/third-party-integrations';
import { default as waitlist } from './products/waitlist';
import { default as reservation } from './products/reservation';
import { default as rutechrobots } from './products/rutech-robots';
import { default as fullservice } from './businessType/fullservice';
import { default as quickservce } from './businessType/quickservice';
import { default as hotpot } from './businessType/hotpot';
import { default as koreanbbq } from './businessType/koreanBBQ';
import { default as bobaTeaDessert } from './businessType/bobaTeaDessert';
import { default as sushiRamen } from './businessType/sushiRamen';
import { default as dimSumPos } from './businessType/dimsum';
// import { default as chineseRestaurant } from './chinese-restaurant';
import { default as pizza } from './businessType/pizza';
import { default as cafe } from './businessType/cafes';
import { default as bar } from './businessType/bars';
import { default as blogs } from './blogs';
import { default as getADemo } from './get-a-demo';
import { default as whyChowbusPOS } from './why-chowbus-pos';
import { default as pricing } from './pricing';
export default {
  'pos-web-site-home-page-enh': {
    ...homepage,
  },
  'point-of-sale': {
    ...pointOfSable,
  },

  'pos-handheld': {
    ...handheld,
  },
  'pos-tablet-ordering': {
    ...tableordering,
  },
  'pos-qrcode-ordering': {
    ...qrcode,
  },

  'pos-kiosk-ordering': {
    ...kiosk,
  },

  'pos-loyalty': {
    ...loyalty,
  },
  'pos-online-ordering': {
    ...onlineordering,
  },
  'pos-thirdparty-integration': {
    ...thirdpartyIntegration,
  },

  'pos-waitlist': {
    ...waitlist,
  },
  'pos-reservation': {
    ...reservation,
  },

  'pos-rutech-robot': {
    ...rutechrobots,
  },

  'pos-full-service': {
    ...fullservice,
  },
  'pos-quick-service': {
    ...quickservce,
  },

  'pos-hot-pot': {
    ...hotpot,
  },
  'pos-korean-bbq': {
    ...koreanbbq,
  },

  'pos-boba-tea-dessert': {
    ...bobaTeaDessert,
  },

  // 'pos-japanese-ramen': {
  //     ...japaneseRamen,
  // },
  'pos-pizza': {
    ...pizza,
  },

  'pos-cafes': {
    ...cafe,
  },
  'pos-bars': {
    ...bar,
  },
  'pos-dim-sum': {
    ...dimSumPos,
  },

  // 'pos-blogs': {
  //   ...blogs,
  // },

  'pos-get-a-demo': {
    ...getADemo,
  },

  'pos-why-chowbus-pos': {
    ...whyChowbusPOS,
  },

  'pos-pricing': {
    ...pricing,
  },
  'pos-japanese-ramen': {
    ...sushiRamen,
  },

  default: {
    ...homepage,
  },
};
