import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Row, Col, Button, Grid, Carousel } from 'antd';
import { useSelector } from 'react-redux';
import indexStyles from '../index.less';
import { JoinClassName } from 'src/utils/utils';

const { useBreakpoint } = Grid;

const CommonSection = ({ title, desc, children, className, outerClassName = '' }) => {
  const screens = useBreakpoint();
  return (
    <React.Fragment>
      <div
        className={JoinClassName(
          indexStyles.commonSectionWrapper,
          outerClassName,
          screens.xs ? indexStyles.forMobile : '',
          screens.md && !screen.lg ? indexStyles.forMDDevice : '', //768
          screens.sm && !screens.md ? indexStyles.forSMDevice : '' //576
        )}
      >
        <div
          className={JoinClassName(
            indexStyles.commonSection,
            title ? '' : indexStyles.commonSectionNoHeader,
            className,
            screens.xs ? indexStyles.mobile : ''
          )}
        >
          {title && (
            <Row align={'middle'} justify={'center'} wrap={false}>
              <Col xs={19} md={18}>
                <h2 className={JoinClassName(indexStyles.title_h2, desc ? indexStyles.with_desc : '')}>{title}</h2>
              </Col>
            </Row>
          )}
          {desc && (
            <Row align={'middle'} justify={'center'} wrap={false}>
              <Col xs={19} md={24}>
                {/* <p className={JoinClassName(indexStyles.subdesc)}>{desc}</p> */}
                <h2 className={JoinClassName(indexStyles.commonTopDesc)}>{desc}</h2>
              </Col>
            </Row>
          )}

          <Row align={'middle'} justify={'center'} wrap={false}>
            <Col xs={24}>{children}</Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommonSection;
