const ASSETS_PREFIX = process.env.ASSETS_PREFIX;

const FontFolderPrefix = `${ASSETS_PREFIX}/fonts`;
// Proxima Nova (for english)
const ProximaNovaFontFolderPrefix = `${FontFolderPrefix}/Proxima_Nova`;
const ProximaNovaBold = `${ProximaNovaFontFolderPrefix}/bold.otf`;
const ProximaNovSemiBold = `${ProximaNovaFontFolderPrefix}/semibold.otf`;
const ProximaNovRegular = `${ProximaNovaFontFolderPrefix}/regular.otf`;

// Ping Fang SC
const PingFangSCFontFolderPrefix = `${FontFolderPrefix}/PingFang_SC`;
const PingFangSCBold = `${PingFangSCFontFolderPrefix}/bold.otf`;
const PingFangSCSemiBold = `${PingFangSCFontFolderPrefix}/semibold.otf`;
const PingFangSCRegular = `${PingFangSCFontFolderPrefix}/regular.otf`;
const PingFangSCLight = `${PingFangSCFontFolderPrefix}/light.otf`;
const PingFangSCThin = `${PingFangSCFontFolderPrefix}/thin.otf`;

// Source Han Sans
const SourceHanSansFontFolderPrefix = `${FontFolderPrefix}/Source_Han_Sans`;
const SourceHanSansBold = `${SourceHanSansFontFolderPrefix}/bold.otf`;
const SourceHanSansSemiBold = `${SourceHanSansFontFolderPrefix}/semibold.otf`;
const SourceHanSansRegular = `${SourceHanSansFontFolderPrefix}/regular.otf`;
const SourceHanSansLight = `${SourceHanSansFontFolderPrefix}/light.otf`;
const SourceHanSansThin = `${SourceHanSansFontFolderPrefix}/thin.otf`;

// NotoSerif
const NotoSerifFontFolderPrefix = `${FontFolderPrefix}/NotoSerif`;
const NotoSerifFontBold = `${NotoSerifFontFolderPrefix}/bold.ttf`;
const NotoSerifFontRegular = `${NotoSerifFontFolderPrefix}/regular.ttf`;

// Quando
const QuandoFontRegular = `${FontFolderPrefix}/Quando/regular.ttf`;

// HarmonyOS Sans SC
const HarmonyOSSansSCFontFolderPrefix = `${FontFolderPrefix}/HarmonyOS_Sans_SC`;
const HarmonyOSSansSCBold = `${HarmonyOSSansSCFontFolderPrefix}/bold.ttf`;
const HarmonyOSSansSCRegular = `${HarmonyOSSansSCFontFolderPrefix}/regular.ttf`;
const HarmonyOSSansSCLight = `${HarmonyOSSansSCFontFolderPrefix}/light.ttf`;

// HarmonyOS Sans TC
const HarmonyOSSanTCFontFolderPrefix = `${FontFolderPrefix}/HarmonyOS_Sans_TC`;
const HarmonyOSSansTCBold = `${HarmonyOSSanTCFontFolderPrefix}/bold.ttf`;
const HarmonyOSSansTCRegular = `${HarmonyOSSanTCFontFolderPrefix}/regular.ttf`;
const HarmonyOSSansTCLight = `${HarmonyOSSanTCFontFolderPrefix}/light.ttf`;

// Alimama Dongfangdakai
const AlimamaDongFangDaKaiFont = `${FontFolderPrefix}/AlimamaDongFangDaKai/regular.otf`;

// Alimama FangYuan
const AlimamaFangYuanTiVFFont = `${FontFolderPrefix}/AlimamaFangYuanTiVF/thin.ttf`;

// LINESeedJP_A (for japanese)
const LINESeedJPFontFolderPrefix = `${FontFolderPrefix}/LINESeedJP_A`;
const LINESeedJPFontBold = `${LINESeedJPFontFolderPrefix}/bold.otf`;
const LINESeedJPFontSemiBold = `${LINESeedJPFontFolderPrefix}/semibold.otf`;
const LINESeedJPFontRegular = `${LINESeedJPFontFolderPrefix}/regular.otf`;
const LINESeedJPFontThin = `${LINESeedJPFontFolderPrefix}/thin.otf`;

// BIZUDMINCHO
const BIZUDMINCHOFontFolderPrefix = `${FontFolderPrefix}/BIZUDMINCHO`;
const BIZUDMINCHOFontBold = `${BIZUDMINCHOFontFolderPrefix}/bold.ttf`;
const BIZUDMINCHOFontRegular = `${BIZUDMINCHOFontFolderPrefix}/regular.ttf`;

// 寒蝉锦书宋Pro
const HanChanJinshuSongProFont = `${FontFolderPrefix}/HanChanJinshuSongPro/regular.otf`;

// 寒蝉正楷体
const HanChanZhengKaiFont = `${FontFolderPrefix}/HanChanZhengKai/regular.ttf`;

// 霞鹜铭心宋
const LuWuMingXinSongFont = `${FontFolderPrefix}/LuWuMingXinSong/regular.ttf`;

// InstrumentSerif
const InstrumentSerifFont = `${FontFolderPrefix}/InstrumentSerif/regular.ttf`;

// SairaCondensed
const SairaCondensedFont = `${FontFolderPrefix}/SairaCondensed/regular.ttf`;

export const FONT_FAMILY = {
  PROXIMA_NOVA: 'Proxima Nova',
  PINGFANG_SC: 'PingFang SC',
  SOURCE_HAN_SANS: 'Source Han Sans',
  NOTOSERIF: 'Noto Serif',
  QUANDO: 'Quando',
  HARMONYOS_SANS_SC: 'HarmonyOS Sans SC',
  HARMONYOS_SANS_TC: 'HarmonyOS Sans TC',
  ALIMAMADONGFANGDAKAI: 'Alimama DongFangDaKai',
  ALIMAMAFANGYUANTIVF: 'Alimama FangYuan',
  BIZUDMINCHO: 'BIZUDMINCHO',
  LINESEEDJPA: 'LINESeedJP A',
  HANCHANJINGSHUSONGPRO: '寒蝉锦书宋Pro',
  HANCHANZHENGKAI: '寒蝉正楷体',
  LUWUMINGXINSONG: '霞鹜铭心宋',
  INSTUMENTSERIF: 'Instrument Serif',
  SAIRACONDENSED: 'Saira Condensed',
};

const ProximaNovaFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.PROXIMA_NOVA}";
    src: local("${FONT_FAMILY.PROXIMA_NOVA}"), url(${ProximaNovaBold}) format("opentype");
    font-weight: 700 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.PROXIMA_NOVA}";
    src: local("${FONT_FAMILY.PROXIMA_NOVA}"), url(${ProximaNovSemiBold}) format("opentype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.PROXIMA_NOVA}";
    src: local("${FONT_FAMILY.PROXIMA_NOVA}"), url(${ProximaNovRegular}) format("opentype");
    font-weight: 100 500;
    font-style: normal;
  }
`;

const PingFangSCFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.PINGFANG_SC}";
    src: local("${FONT_FAMILY.PINGFANG_SC}"), url(${PingFangSCBold}) format("opentype");
    font-weight: 700 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.PINGFANG_SC}";
    src: local("${FONT_FAMILY.PINGFANG_SC}"), url(${PingFangSCSemiBold}) format("opentype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.PINGFANG_SC}";
    src: local("${FONT_FAMILY.PINGFANG_SC}"), url(${PingFangSCRegular}) format("opentype");
    font-weight: 400 500;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.PINGFANG_SC}";
    src: local("${FONT_FAMILY.PINGFANG_SC}"), url(${PingFangSCLight}) format("opentype");
    font-weight: 200 300;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.PINGFANG_SC}";
    src: local("${FONT_FAMILY.PINGFANG_SC}"), url(${PingFangSCThin}) format("opentype");
    font-weight: 100;
    font-style: normal;
  }
`;

const NotoSerifFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.NOTOSERIF}";
    src: url(${NotoSerifFontBold}) format("truetype");
    font-weight: 600 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.NOTOSERIF}";
    src: url(${NotoSerifFontRegular}) format("truetype");
    font-weight: 100 500;
    font-style: normal;
  }
`;

const QuandoFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.QUANDO}";
    src: url(${QuandoFontRegular}) format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const SourceHanSansFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.SOURCE_HAN_SANS}";
    src: url(${SourceHanSansBold}) format("opentype");
    font-weight: 700 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.SOURCE_HAN_SANS}";
    src: url(${SourceHanSansSemiBold}) format("opentype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.SOURCE_HAN_SANS}";
    src: url(${SourceHanSansRegular}) format("opentype");
    font-weight: 400 500;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.SOURCE_HAN_SANS}";
    src: url(${SourceHanSansLight}) format("opentype");
    font-weight: 200 300;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.SOURCE_HAN_SANS}";
    src: url(${SourceHanSansThin}) format("opentype");
    font-weight: 100;
    font-style: normal;
  }
`;

const HarmonyOSSansSCFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.HARMONYOS_SANS_SC}";
    src: url(${HarmonyOSSansSCBold}) format("truetype");
    font-weight: 700 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.HARMONYOS_SANS_SC}";
    src: url(${HarmonyOSSansSCRegular}) format("truetype");
    font-weight: 400 600;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.HARMONYOS_SANS_SC}";
    src: url(${HarmonyOSSansSCLight}) format("truetype");
    font-weight: 100 300;
    font-style: normal;
  }
`;

const HarmonyOSSansTCFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.HARMONYOS_SANS_TC}";
    src: url(${HarmonyOSSansTCBold}) format("truetype");
    font-weight: 700 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.HARMONYOS_SANS_TC}";
    src: url(${HarmonyOSSansTCRegular}) format("truetype");
    font-weight: 400 600;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.HARMONYOS_SANS_TC}";
    src: url(${HarmonyOSSansTCLight}) format("truetype");
    font-weight: 100 300;
    font-style: normal;
  }
`;

const AlimamaDongFangDaKaiFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.ALIMAMADONGFANGDAKAI}";
    src: url(${AlimamaDongFangDaKaiFont}) format("opentype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const AlimamaFangYuanTiVFFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.ALIMAMAFANGYUANTIVF}";
    src: url(${AlimamaFangYuanTiVFFont}) format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const LINESeedJPAFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.LINESEEDJPA}";
    src: url(${LINESeedJPFontBold}) format("truetype");
    font-weight: 700 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.LINESEEDJPA}";
    src: url(${LINESeedJPFontSemiBold}) format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.LINESEEDJPA}";
    src: url(${LINESeedJPFontRegular}) format("truetype");
    font-weight: 400 500;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.LINESEEDJPA}";
    src: url(${LINESeedJPFontThin}) format("truetype");
    font-weight: 100 300;
    font-style: normal;
  }
`;

const BIZUDMINCHOFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.BIZUDMINCHO}";
    src: url(${BIZUDMINCHOFontBold}) format("truetype");
    font-weight: 600 900;
    font-style: normal;
  }
  @font-face {
    font-family: "${FONT_FAMILY.BIZUDMINCHO}";
    src: url(${BIZUDMINCHOFontRegular}) format("truetype");
    font-weight: 100 500;
    font-style: normal;
  }
`;

const HanChanJinshuSongProFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.HANCHANJINGSHUSONGPRO}";
    src: url(${HanChanJinshuSongProFont}) format("opentype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const HanChanZhengKaiFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.HANCHANZHENGKAI}";
    src: url(${HanChanZhengKaiFont}) format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const LuWuMingXinSongFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.LUWUMINGXINSONG}";
    src: url(${LuWuMingXinSongFont}) format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const InstrumentSerifFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.INSTUMENTSERIF}";
    src: url(${InstrumentSerifFont}) format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

const SairaCondensedFontFace = `
  @font-face {
    font-family: "${FONT_FAMILY.SAIRACONDENSED}";
    src: url(${SairaCondensedFont}) format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }
`;

export const FONT_FACES = {
  [FONT_FAMILY.PROXIMA_NOVA]: ProximaNovaFontFace,
  [FONT_FAMILY.PINGFANG_SC]: PingFangSCFontFace,
  [FONT_FAMILY.SOURCE_HAN_SANS]: SourceHanSansFontFace,
  [FONT_FAMILY.NOTOSERIF]: NotoSerifFontFace,
  [FONT_FAMILY.HARMONYOS_SANS_SC]: HarmonyOSSansSCFontFace,
  [FONT_FAMILY.HARMONYOS_SANS_TC]: HarmonyOSSansTCFontFace,
  [FONT_FAMILY.ALIMAMADONGFANGDAKAI]: AlimamaDongFangDaKaiFontFace,
  [FONT_FAMILY.ALIMAMAFANGYUANTIVF]: AlimamaFangYuanTiVFFontFace,
  [FONT_FAMILY.BIZUDMINCHO]: BIZUDMINCHOFontFace,
  [FONT_FAMILY.LINESEEDJPA]: LINESeedJPAFontFace,
  [FONT_FAMILY.QUANDO]: QuandoFontFace,
  [FONT_FAMILY.HANCHANJINGSHUSONGPRO]: HanChanJinshuSongProFontFace,
  [FONT_FAMILY.HANCHANZHENGKAI]: HanChanZhengKaiFontFace,
  [FONT_FAMILY.LUWUMINGXINSONG]: LuWuMingXinSongFontFace,
  [FONT_FAMILY.INSTUMENTSERIF]: InstrumentSerifFontFace,
  [FONT_FAMILY.SAIRACONDENSED]: SairaCondensedFontFace,
};

export const FONT_FAMILY_GROUP = {
  english: {
    name: 'English',
    foreign_name: '英文',
    fontFamilies: [
      FONT_FAMILY.INSTUMENTSERIF,
      FONT_FAMILY.NOTOSERIF,
      FONT_FAMILY.PROXIMA_NOVA,
      FONT_FAMILY.QUANDO,
      FONT_FAMILY.SAIRACONDENSED,
    ],
  },
  simplified_chinese: {
    name: 'Chinese(Simplified)',
    foreign_name: '中文(简体)',
    fontFamilies: [
      FONT_FAMILY.ALIMAMADONGFANGDAKAI,
      FONT_FAMILY.ALIMAMAFANGYUANTIVF,
      FONT_FAMILY.HARMONYOS_SANS_SC,
      FONT_FAMILY.HANCHANJINGSHUSONGPRO,
      FONT_FAMILY.HANCHANZHENGKAI,
      FONT_FAMILY.LUWUMINGXINSONG,
    ],
  },
  tranditional_chinese: {
    name: 'Chinese(Tranditional)',
    foreign_name: '中文(繁体)',
    fontFamilies: [FONT_FAMILY.HARMONYOS_SANS_TC],
  },
  japanese: {
    name: 'Japanese',
    foreign_name: '日文',
    fontFamilies: [FONT_FAMILY.BIZUDMINCHO, FONT_FAMILY.LINESEEDJPA],
  },
};
