import React, { useRef, useEffect, useMemo } from 'react';
import PageJSONLDMetaData from 'src/consts/poswebsite_content/jsonLDSchema';
import { LOCALE_EN } from 'src/consts';
import useLocale from './useLocale';

export default function useJSONLDMeta({ pageId }) {
  const locale = useLocale();
  const jsonLDObjectList = useMemo(() => {
    //pos-web-site-home-page-enh
    let realPageId = pageId || '';
    let combinePages = realPageId.split(' ');
    realPageId = combinePages[combinePages.length - 1] || '';
    let pageJSONLDMetaData = PageJSONLDMetaData[realPageId];
    if (!pageJSONLDMetaData) {
      return [];
    }
    let jsonLDObjectList = pageJSONLDMetaData[locale];
    if (jsonLDObjectList.length === 0) {
      jsonLDObjectList = pageJSONLDMetaData[LOCALE_EN];
    }
    return jsonLDObjectList || [];
  }, [locale, pageId]);

  return jsonLDObjectList;
}
