import React from 'react';
import { Row, Col } from 'antd';
import Link from 'next/link';
import styles from './index.less';

const HelpLinks = [
  {
    name: 'Download the App',
    link: '/download',
  },
  {
    name: 'FAQs',
    link: 'https://www.chowbus.com/faq',
  },
  {
    name: 'Student Discount',
    link: 'https://www.chowbus.com/student-discount',
  },
];

const AboutUsLinks = [
  {
    name: 'Services',
    link: 'https://www.chowbus.com/services',
  },
  {
    name: 'Chowbus Plus',
    link: 'https://www.chowbus.com/chowbus-plus',
  },
  {
    name: 'Rewards',
    link: 'https://www.chowbus.com/rewards',
  },
  {
    name: 'Affiliate',
    link: 'https://www.chowbus.com/affiliate',
  },
  {
    name: 'Careers',
    link: 'https://www.chowbus.com/careers',
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/chowbus/',
  },
];

const ContactUsLinks = [
  {
    name: 'Restaurants',
    link: 'https://www.chowbus.com/restaurants',
  },
];

const WebSiteHelpLinks = [
  {
    name: 'Get a Demo',
    link: '/get-a-demo',
  },
  {
    name: 'Pricing',
    link: '/pricing',
  },
  {
    name: 'Why Chowbus',
    link: '/why-chowbus-pos',
  },
];

const WebSiteCustomersLinks = [
  {
    name: 'Login',
    link: '/dashboard',
  },
  {
    name: 'Refer a Restaurant',
    target: '_blank',
    link: 'https://chowbus60606.my.site.com/LandingPage/',
  },
];

const ENWebSiteCustomersLinks = [
  {
    name: 'Login',
    link: '/dashboard',
  },
  {
    name: 'Refer a Restaurant',
    target: '_blank',
    link: 'https://chowbus60606.my.site.com/ENLandingPage/',
  },
];

const WebSiteCompanyLinks = [
  {
    name: 'Blog',
    link: '/blog',
  },
  {
    name: 'Careers',
    link: 'https://www.chowbus.com/careers',
  },
];

const LinkGroup = ({ groupName, links }) => {
  return (
    <div className="link-group">
      <div className="link-group-name">{groupName}</div>
      <div className="links">
        {links.map(({ link, name, target }) => {
          const anchor = !link.startsWith('http') ? (
            <Link href={link} key={name}>
              <a className="footer-link">{name}</a>
            </Link>
          ) : (
            <a className="footer-link" target={target || '_self'} href={link} key={name}>
              {name}
            </a>
          );

          return <div key={link}>{anchor}</div>;
        })}
      </div>
    </div>
  );
};

const GeneralFooter = () => {
  return (
    <div className={styles.GeneralFooter}>
      <div className="container">
        <Row gutter={[36, 36]}>
          <Col xs={24} xl={6}>
            <img src="/home/chowbus_logo_white.png" width="172" height="33" alt="chowbus white logo" />
          </Col>
          <Col xs={24} xl={18}>
            <Row>
              <Col xs={12} xl={8}>
                <LinkGroup groupName="Help" links={HelpLinks} />
              </Col>
              <Col xs={12} xl={8}>
                <LinkGroup groupName="About Us" links={AboutUsLinks} />
              </Col>
              <Col xs={12} xl={8}>
                <LinkGroup groupName="Contact Us" links={ContactUsLinks} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const GeneralWebSiteFooter = ({ locale }) => {
  return (
    <div className={styles.GeneralFooter}>
      <div className="container">
        <Row gutter={[36, 36]}>
          <Col xs={24} xl={6}>
            <img
              src={`${process.env.ASSETS_PREFIX}/home/chowbus_logo_white.png`}
              width="172"
              height="33"
              alt="chowbus white logo"
            />
          </Col>
          <Col xs={24} xl={18}>
            <Row>
              <Col xs={12} xl={8}>
                {locale === 'en' && <LinkGroup groupName="Customers" links={ENWebSiteCustomersLinks} />}
                {locale === 'zh' && <LinkGroup groupName="Customers" links={WebSiteCustomersLinks} />}
              </Col>
              <Col xs={12} xl={8}>
                <LinkGroup groupName="Company" links={WebSiteCompanyLinks} />
              </Col>
              <Col xs={12} xl={8}>
                <LinkGroup groupName="Help" links={WebSiteHelpLinks} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GeneralFooter;
